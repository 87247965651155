<template>
  <v-menu offset-y transition="slide-y-transition">

    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="wsATTENTION"  v-if="!groupAction" size="28"  v-bind="attrs" v-on="on" small icon dark>
        <v-icon >mdi-pencil-circle</v-icon>
      </v-btn>
      <v-btn v-else
             v-bind="attrs" v-on="on"
             :color="wsATTENTION"
             class="noCaps"
             dark small  >
        GroupAction
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list dense>

      <v-list-item-group>
        <div v-for="(item,i) in actions" :key="i" >
          <router-link :to="route" class="noUnderline" v-if="item.page">
            <v-list-item @click="action(item.value)">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
          </router-link>
          <v-list-item v-else @click="action(item.value)" >
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "wsEditMenu",
  props: ['actions','route','groupAction'],
  methods : {
    action(action){
      this.$emit('action',action)
    }
  }
}
</script>

<style scoped>

</style>